import React from "react"
import Layout from "../components/layout/layout"
import AboutSection from "../components/about/about"

const AboutPage = () => (
  <Layout>
    <AboutSection />
  </Layout>
)

export default AboutPage
